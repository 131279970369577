
.card .loader-container[data-v-c193a272]{
    position: absolute;
}
.slide-enter-active[data-v-c193a272] {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}
.slide-leave-active[data-v-c193a272] {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to[data-v-c193a272], .slide-leave[data-v-c193a272] {
   max-height: 500px;
   overflow: hidden;
}
.slide-enter[data-v-c193a272], .slide-leave-to[data-v-c193a272] {
   overflow: hidden;
   max-height: 0;
}
