
.loader-container {
    z-index:1000;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:white;
    opacity:0.9;
    color:#404040;
}
.loader-container-table {
    z-index:1000;
    position:absolute;
    left:0;
    top:110px;
    width:100%;
    height:calc(100% - 110px);
    background:white;
    opacity:0.9;
    color:#404040;
}
